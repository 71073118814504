import React from 'react';
import { Box, Text, ResponsiveContext } from 'grommet';

const ErrorLoading = () => {
  return (
    <ResponsiveContext.Consumer>
      {size => (
        <Box
          align='center'
          justify='center'
          height='80vh'
        >
          <Box
            align='center'
            width='medium'
            pad={ size === 'small' ? 'large' : 'small' }
            gap={ size === 'small' ? 'medium' : 'small' }
          >
            <Text
              size='xlarge'
              textAlign='center'
            >
              Ops! Algo deu errado :(
            </Text>

            <Text
              size='small'
              color='dark-5'
              textAlign='center'
            >
              Estamos constrangidos por isso. Não se preocupe, estamos trabalhando para resolver.
            </Text>
          </Box>
        </Box>
      )}
    </ResponsiveContext.Consumer>
  )
}

export default ErrorLoading;
