const urlRoutes = ({
  home: '/',
  manifestationFormUrl: '/:identity',
  manifestationThankUrl: '/:identity/thank',
  manifestitionTermUrl: '/:identity/term',

  site: 'https://gerenciameumandato.com.br',
});

export default urlRoutes;
