import React from 'react';

import Header from '../components/header';
import Term from '../components/term';

const HomePage = () => {
  return (
    <>
      <Header />
      <Term />
    </>
  );
}

export default HomePage;
