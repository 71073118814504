import React from 'react';
import { ResponsiveContext, Box, Text } from 'grommet';
import { connect } from 'react-redux';

import { decryptAes } from '../../../utils';

const Title = (props) => {
  const {
    encryptedClientDetail,
    clientDetailError,
  } = props;

  if(!encryptedClientDetail || clientDetailError) {
    return null;
  }

  const decipheredData = encryptedClientDetail ? decryptAes(encryptedClientDetail) : null;
  const dataJson = JSON.parse(decipheredData);

  console.log('Title dataJson', dataJson);

  return (
    <ResponsiveContext.Consumer>
      {size => (
        <Box
          align='center'
        >
          <Box
            width='xlarge'
            pad={{ horizontal: 'medium' }}
            margin={{
              vertical: 'medium',
            }}
          >
            <Box
              width='large'
            >
              <Text
                color='dark-2'
                size={ size === 'small' ? 'large' :  'xlarge' }
              >
                {dataJson?.profile?.displayName || process.env.REACT_APP_FUNCTION_NAME}
              </Text>

              <Text
                color='dark-6'
                size='small'
              >
                Uma forma fácil e segura de manifestar sua sugestão, reclamação, solicitação.
              </Text>
            </Box>
          </Box>
        </Box>
      )}
    </ResponsiveContext.Consumer>
  );
}

const mapStateToProps = ({ clientDetails }) => ({
  encryptedClientDetail: clientDetails.encryptedClientDetail,
  clientDetailError: clientDetails.clientDetailError,
});

const mapdispatchToProps = null;


export default connect(mapStateToProps, mapdispatchToProps)(Title);
