import React from 'react';
import { ResponsiveContext, Box, Text } from 'grommet';

const Header = () => {
  return (
    <ResponsiveContext.Consumer>
      {size => (
        <Box
          pad={ size === 'small' ? 'medium' : 'small' }
          align='center'
          background='brand'
          margin={{
            bottom: size === 'small' ? 'xlarge' : 'large'
          }}
        >
          <Box
            width='xlarge'
            direction='row'
            align='baseline'
            gap='xsmall'
          >
            <Text
              size='xlarge'
              color='write'
            >
              {process.env.REACT_APP_FUNCTION_NAME}
            </Text>

            <Text
              size='xsmall'
              color='write'
            >
              Termo de uso e Política de privacidade
            </Text>
          </Box>
        </Box>
      )}
    </ResponsiveContext.Consumer>
  )
}

export default Header;
