import React from 'react';

import Warn from '../components/warn';
import Title from '../components/title';
import Form from '../components/form';
import Footer from '../components/footer';

const HomePage = () => {
  return (
    <>
      <Warn />

      <Title />
      <Form />

      <Footer />
    </>
  )
}

export default HomePage;
