import React from 'react';

import Header from '../components/header';
import Thank from '../components/thank';

const HomePage = () => {
  return (
    <>
      <Header />
      <Thank />
    </>
  )
}

export default HomePage;
