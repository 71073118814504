import React from 'react';
import { Box, ResponsiveContext, Spinner } from 'grommet';

const Loading = () => {
  return (
    <ResponsiveContext.Consumer>
      {size => (
        <Box
          align='center'
          justify='center'
          height='80vh'
        >
          <Box
            align='center'
            width='medium'
            pad={ size === 'small' ? 'large' : 'small' }
          >
            <Spinner size='medium' />
          </Box>
        </Box>
      )}
    </ResponsiveContext.Consumer>
  )
}

export default Loading;
