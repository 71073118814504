import React from 'react';
import { Box } from 'grommet';

import Header from '../components/header';
import Title from '../components/title';
import ForPopulation from '../components/for-population';
import ForPoliticians from '../components/for-politicians';
import Footer from '../components/footer';

const HomePage = () => {
  return (
    <>
      <Header />

      <Box align='center'>
        <Box
          width='xlarge'
          gap='large'
          pad={{ horizontal: 'medium' }}
        >
          <Title />
          <ForPopulation />
          <ForPoliticians />
          <Footer />
        </Box>
      </Box>
    </>
  )
}

export default HomePage;
