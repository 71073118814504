import { BrowserRouter as Router, Route } from 'react-router-dom';
import { Analytics } from '../../components';
import { HomePage as PageForm } from '../../modules/manifestation/pages';
import { HomePage as PageThank } from '../../modules/thank/pages';
import { HomePage as PageSite } from '../../modules/home/pages';
import { HomePage as PageTerm } from '../../modules/term/pages';

export default function Routes() {
  return (
    <Router>
      <Analytics>
        <Route exact path='/:identifier' component={PageForm} />
        <Route exact path='/:identifier/thank' component={PageThank} />
        <Route exact path='/:identifier/term' component={PageTerm} />
        <Route exact path='/' component={PageSite} />
      </Analytics>
    </Router>
  )
}
