import { Box, Text, Anchor, ResponsiveContext } from 'grommet';

const Footer = () => {
  return (
    <ResponsiveContext.Consumer>
      {size => (
        <>
          <Box
            border={{ side: 'top', color: 'light-3' }}
            margin={{ top: 'small', bottom: 'large' }}
          />

          <Box
            direction={ size === 'small' ? 'row' : 'row' }
            gap={ size === 'small' ? 'medium' : 'xlarge' }
            margin={{ bottom: 'large' }}
            justify='between'
          >
            <Box
              width={ size === 'small' || size === 'medium' ? 'small' : 'medium' }
            >
              <Text
                color='dark-2'
                size={ size === 'small' ? 'small' : 'medium' }
              >
                Sou político ou representante e fiquei interessado.
              </Text>
            </Box>

            <Box
              width={ size === 'small' || size === 'medium' ? 'small' : 'medium' }
              gap='xsmall'
              align='end'
            >
              <Anchor
                size={ size === 'small' ? 'small' : 'medium' }
                color='brand'
                label={process.env.REACT_APP_CONTAT_PHONE}
                target='blank'
                href='https://web.whatsapp.com/send?phone=5519988508644'
              />

              <Anchor
                size={ size === 'small' ? 'small' : 'medium' }
                color='brand'
                label={process.env.REACT_APP_CONTAT_EMAIL}
                href={`mailto:${process.env.REACT_APP_CONTAT_EMAIL}`}
              />
            </Box>
          </Box>
        </>
      )}
      </ResponsiveContext.Consumer>
  );
}

export default Footer;
