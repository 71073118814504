import React from 'react';
import { ResponsiveContext, Box, Text, Anchor } from 'grommet';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import { decryptAes } from '../../../utils';

import urlRoutes from '../../../config/url-routes';

const Footer = (props) => {
  const { identifier } = props.match.params;

  const {
    encryptedClientDetail,
    clientDetailError,
  } = props;

  if(!encryptedClientDetail || clientDetailError) {
    return null;
  }

  const decipheredData = encryptedClientDetail ? decryptAes(encryptedClientDetail) : null;
  const dataJson = JSON.parse(decipheredData);


  return (
    <ResponsiveContext.Consumer>
      {size => (
        <>
          <Box
            border={{ side: 'top', color: 'light-3' }}
          />

          <Box
            align='center'
          >
            <Box
              direction={
                size === 'small' || size === 'medium' ? 'column' : 'row'
              }
              gap={
                size === 'small' || size === 'medium' ? 'large' : 'xlarge'
              }
              margin={{ vertical: 'large' }}
              justify='between'
              width='xlarge'
              pad={{ horizontal: 'medium' }}
            >
              <Box
                width={ size === 'small' ? 'xlarge' : 'medium' }
                gap='small'
              >
                <Text
                  color='dark-2'
                  size='medium'
                >
                  Termo de uso e política de privacidade
                </Text>

                <Text
                  color='dark-6'
                  size='small'
                >
                  Ao utilizar o {process.env.REACT_APP_FUNCTION_NAME}, você concorda com os termos de uso e a política de privacidade.
                </Text>

                <Anchor
                  color='brand'
                  href={urlRoutes.manifestitionTermUrl.replace(/:identity/gi, identifier)}
                  target='_blank'
                  label={
                    <Text size='small'>Termo e Política de privacidade</Text>
                  }
                />
              </Box>

              {dataJson?.profile?.displayName && (
                <Box
                  width={ size === 'small' ? 'xlarge' : 'medium' }
                  gap='small'
                >
                  <Text
                    color='dark-2'
                    size='medium'
                  >
                    Fale com {dataJson?.profile?.displayName || process.env.REACT_APP_SOFTWARE_NAME}
                  </Text>

                  <Box>
                    <Text
                      color='dark-6'
                      size='small'
                    >
                      {dataJson?.profile?.displayPhoneNumber || process.env.REACT_APP_CONTAT_PHONE}
                    </Text>

                    <Text
                      color='dark-6'
                      size='small'
                    >
                      {dataJson?.profile?.displayEmail || process.env.REACT_APP_CONTAT_EMAIL}
                    </Text>
                  </Box>
                </Box>
              )}

              <Box
                width={ size === 'small' ? 'xlarge' : 'medium' }
                gap='small'
              >
                <Text
                  color='dark-2'
                  size='medium'
                >
                  Sobre o {process.env.REACT_APP_FUNCTION_NAME}
                </Text>

                <Text
                  color='dark-6'
                  size='small'
                >
                  O {process.env.REACT_APP_FUNCTION_NAME} é criado e gerenciado por <Anchor href={urlRoutes.site}>{process.env.REACT_APP_SOFTWARE_NAME}</Anchor>.
                  Ele têm como objetivo facilitar a comunicação entre a população e seus representantes políticos.
                </Text>
              </Box>
            </Box>
          </Box>
        </>
      )}
      </ResponsiveContext.Consumer>
  );
}

const mapStateToProps = ({ clientDetails }) => ({
  encryptedClientDetail: clientDetails.encryptedClientDetail,
  clientDetailError: clientDetails.clientDetailError,
});

const mapdispatchToProps = null;

export default withRouter(connect(mapStateToProps, mapdispatchToProps)(Footer));
