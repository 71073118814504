import React from 'react';
import { ResponsiveContext, Box, Text } from 'grommet';
import { connect } from 'react-redux';

import { decryptAes } from '../../../utils';

const Warn = (props) => {
  const {
    encryptedClientDetail,
    clientDetailError,
  } = props;

  if(!encryptedClientDetail || clientDetailError) {
    return null;
  }

  const decipheredData = encryptedClientDetail ? decryptAes(encryptedClientDetail) : null;
  const dataJson = JSON.parse(decipheredData);

  if(!dataJson?.trl) {
    return (<Box
      pad={{ vertical: 'xxsmall' }}
      background='brand'
    />);
  }


  return (
    <ResponsiveContext.Consumer>
      {size => (
        <Box
          pad={{ horizontal: 'large', vertical: 'xsmall' }}
          align='center'
          background='status-warning'
        >
          <Text
            size='small'
            color='white'
          >
            Essa conta esta em período de avaliação.
          </Text>
        </Box>
      )}
    </ResponsiveContext.Consumer>
  )
}

const mapStateToProps = ({ clientDetails }) => ({
  encryptedClientDetail: clientDetails.encryptedClientDetail,
  clientDetailError: clientDetails.clientDetailError,
});

const mapdispatchToProps = null;


export default connect(mapStateToProps, mapdispatchToProps)(Warn);
