import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Box, Select, Button, TextInput, TextArea, Text, MaskedInput, ResponsiveContext } from 'grommet';

import { loadData } from '../../../actions/client-details';
import { sendManifestation, changeField } from '../../../actions/form-manifestation';
import { searchCityOptions } from '../../../actions/select-options';

import { ErrorForm, ErrorLoading, Loading } from '../../../components';
import { decryptAes } from '../../../utils';

import selectManifestationTypes from '../../../constants/select-manifestation-type';
import urlRoutes from '../../../config/url-routes';

class Form extends Component {
  constructor(props) {
    super(props);

    const { identifier } = this.props.match.params;
    const { loadData } = this.props;

    loadData(identifier);
  }

  callbackPostSuccess = () => {
    const { identifier } = this.props.match.params;
    const { history } = this.props;

    history.push(urlRoutes.manifestationThankUrl.replace(/:identity/gi, identifier));
  }

  handlePost = formParams => {
    const { sendManifestation } = this.props;

    sendManifestation({
      formParams,
      callbackSuccess: this.callbackPostSuccess
    });
  };

  onSearchCities = text => {
    if (text.length < 2) return;

    const { searchCityOptions } = this.props;
    searchCityOptions({ name: text });
  };

  enableSubmit = () => {
    const {
      name,
      telephone,
      typeId,
      description,
    } = this.props;

    return name && telephone && typeId && description;
  };

  render() {
    const {
      encryptedClientDetail,
      clientDetailError,
      changeField,

      cityOptions,

      loadingPostForm,
      postListError,

      individualRegistration,
      name,
      telephone,
      email,
      cityId,
      district,
      typeId,
      description,
    } = this.props;

    if(!clientDetailError && !encryptedClientDetail) {
      return <Loading />;
    }

    if(clientDetailError) {
      return <ErrorLoading />;
    }

    const decipheredData = encryptedClientDetail ? decryptAes(encryptedClientDetail) : null;
    const dataJson = JSON.parse(decipheredData);

    return (
      <ResponsiveContext.Consumer>
        {size => (
          <>
            <Box
              align='center'
            >
              <Box
                width='xlarge'
                direction={
                  size === 'small' ? 'column' : 'row'
                }
                gap={
                  size === 'medium' ? 'xsmall' : 'large'
                }
              >
                {/* form */}
                <Box
                  align='start'
                  width='large'
                  pad={{ horizontal: 'medium' }}
                >

                  {/* cpf, nome */}
                  <Box
                    direction={ size === 'small' ? 'column' : 'row' }
                    margin={{ bottom: 'small' }}
                    gap='small'
                    fill='horizontal'
                  >
                    <Box
                      gap='xxsmall'
                      fill
                    >
                      <Text size='small' color='dark-3'>CPF</Text>

                      <MaskedInput
                        mask={[
                          { length: 3, regexp: /\d/ },
                          { fixed: '.' },
                          { length: 3, regexp: /\d/ },
                          { fixed: '.' },
                          { length: 3, regexp: /\d/ },
                          { fixed: '-' },
                          { length: 2, regexp: /\d/ }
                        ]}
                        placeholder=' '
                        value={individualRegistration ?? ''}
                        onChange={ event => changeField({ fieldName: 'individualRegistration', value: event.target.value }) }
                      />

                      <ErrorForm errorsList={postListError} fieldName='individualRegistration' />
                    </Box>

                    <Box
                      gap='xxsmall'
                      fill
                    >
                      <Text size='small' color='dark-3'>Nome *</Text>

                      <TextInput
                        value={name ?? ''}
                        onChange={ event => changeField({ fieldName: 'name', value: event.target.value }) }
                      />

                      <ErrorForm errorsList={postListError} fieldName='name' />
                    </Box>
                  </Box>


                  {/* telfone, e-mail */}
                  <Box
                    direction={ size === 'small' ? 'column' : 'row' }
                    margin={{ bottom: 'small' }}
                    gap='small'
                    fill='horizontal'
                  >
                    <Box
                      gap='xxsmall'
                      fill
                    >
                      <Text size='small' color='dark-3'>Telefone *</Text>

                      <MaskedInput
                        mask={[
                          { fixed: '(' },
                          { length: 2, regexp: /\d/ },
                          { fixed: ')' },
                          { fixed: ' ' },
                          { length: 4, regexp: /\d/ },
                          { fixed: '-' },
                          { length: [4, 5], regexp: /\d/ }
                        ]}
                        placeholder=' '
                        value={telephone ?? ''}
                        onChange={ event => changeField({ fieldName: 'telephone', value: event.target.value }) }
                      />

                      <ErrorForm errorsList={postListError} fieldName='telephone' />
                    </Box>

                    <Box
                      gap='xxsmall'
                      fill
                    >
                      <Text size='small' color='dark-3'>E-mail</Text>

                      <TextInput
                        value={email ?? ''}
                        onChange={ event => changeField({ fieldName: 'email', value: event.target.value }) }
                      />

                      <ErrorForm errorsList={postListError} fieldName='email' />
                    </Box>
                  </Box>


                  <Box
                    flex={{ shrink: 0 }}
                    margin={{ top: 'medium', bottom: 'small' }}
                  >
                    <Text size='medium' color='dark-1'>
                      Endereço
                    </Text>
                  </Box>


                  {/* cidade */}
                  <Box
                    fill='horizontal'
                    flex={{ shrink: 0 }}
                    gap='xxsmall'
                    margin={{ bottom: 'small' }}
                  >
                    <Text size='small' color='dark-3'>Cidade</Text>

                    <Select
                      emptySearchMessage='Selecione uma opção'
                      options={cityOptions}
                      value={cityId ?? ''}
                      labelKey='name'
                      valueKey={{ key: 'id', reduce: true }}
                      onChange={({ value: nextValue }) => changeField({ fieldName: 'cityId', value: nextValue })}
                      onSearch={text => this.onSearchCities(text)}
                    />
                  </Box>


                  {/* bairro */}
                  <Box
                    fill='horizontal'
                    flex={{ shrink: 0 }}
                    gap='xxsmall'
                    margin={{ bottom: 'small' }}
                  >
                    <Text size='small' color='dark-3'>Bairro</Text>

                    <TextInput
                      value={district ?? ''}
                      onChange={ event => changeField({ fieldName: 'district', value: event.target.value }) }
                    />
                  </Box>


                  <Box
                    flex={{ shrink: 0 }}
                    margin={{ top: 'medium', bottom: 'small' }}
                  >
                    <Text size='medium' color='dark-1'>
                      Sua manifestação
                    </Text>
                  </Box>


                  {/* Tipo */}
                  <Box
                    fill='horizontal'
                    flex={{ shrink: 0 }}
                    gap='xxsmall'
                    margin={{ bottom: 'small' }}
                  >
                    <Text size='small' color='dark-3'>Tipo *</Text>

                    <Select
                      options={selectManifestationTypes}
                      value={typeId ?? ''}
                      valueKey={{ key: 'id', reduce: true }}
                      labelKey='name'
                      emptySearchMessage='Selecione uma opção'
                      onChange={({ value: nextValue }) => changeField({ fieldName: 'typeId', value: nextValue })}
                    />

                    <ErrorForm errorsList={postListError} fieldName='typeId' />
                  </Box>


                  {/* manifestação */}
                  <Box
                    fill='horizontal'
                    flex={{ shrink: 0 }}
                    gap='xxsmall'
                    margin={{ bottom: 'small' }}
                  >
                    <Text size='small' color='dark-3'>Descreva um pouco sobre sua manifestação *</Text>

                    <TextArea
                      resize='vertical'
                      rows={5}
                      maxLength={500}
                      value={description ?? ''}
                      onChange={ event => changeField({ fieldName: 'description', value: event.target.value }) }
                    />

                    <ErrorForm errorsList={postListError} fieldName='description' />
                  </Box>


                  <Box
                    margin={{ bottom: 'small' }}
                  >
                    <Text
                      size='xsmall'
                      color='dark-3'
                    >
                      Ao clicar em "Enviar", você concorda com os Termos de uso e com a Política de Privacidade.
                    </Text>
                  </Box>


                  <Box
                    direction='row'
                    margin={{ bottom: 'large' }}
                    width='xlarge'
                  >
                    <Button
                      fill='horizontal'
                      primary
                      color='brand'
                      label={ !loadingPostForm ? 'Enviar': 'Enviando...' }
                      disabled={
                        loadingPostForm || !this.enableSubmit()
                      }
                      onClick={() => {
                        this.handlePost({
                          clientId: dataJson.clientId,
                          individualRegistration,
                          name,
                          telephone,
                          email,
                          cityId,
                          district,
                          typeId,
                          description,
                        })
                      }}
                    />
                  </Box>
                </Box>

                {/* descrição */}
                {size === 'small' || size === 'medium' ? null : (
                  <Box
                    gap='medium'
                    width={ size === 'small' ? 'large' : 'medium' }
                    pad={{ horizontal: 'medium' }}
                  >
                    <Text
                      size='medium'
                      color='dark-1'
                      weight='bold'
                    >
                      Começe a colaborar para melhorar a sua cidade.
                    </Text>

                    <Text
                      size='small'
                      color='dark-2'
                    >
                      Fique tranquilo suas informações estarão seguras com a gente.
                    </Text>

                    <Text
                      size='small'
                      color='dark-2'
                    >
                      Sua manifestação estará disponível para o representando político que escolheu
                      e ele poderá entrar em contato com você para mais detalhes.
                    </Text>

                    <Text
                      size='small'
                      color='dark-2'
                    >
                      Ao final do cadastro iremos gerar um número de protocolo,
                      guarde ele para facilitar o seu contato com o parlamentar afim de saber a situação.
                    </Text>
                  </Box>
                )}
              </Box>
            </Box>
          </>
        )}
      </ResponsiveContext.Consumer>
    )
  }
}

const mapStateToProps = ({ clientDetails, formManifestation, selectOptions }) => ({
  encryptedClientDetail: clientDetails.encryptedClientDetail,
  clientDetailError: clientDetails.clientDetailError,

  cityOptions: selectOptions.cityOptions,

  loadingPostForm: formManifestation.loadingPostForm,
  postListError: formManifestation.postListError,

  individualRegistration: formManifestation.individualRegistration,
  name: formManifestation.name,
  telephone: formManifestation.telephone,
  email: formManifestation.email,
  cityId: formManifestation.cityId,
  district: formManifestation.district,
  typeId: formManifestation.typeId,
  description: formManifestation.description,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({
    loadData,
    sendManifestation,
    changeField,
    searchCityOptions,
  }, dispatch)

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Form));
