import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { ResponsiveContext, Box, Text } from 'grommet';

import { loadData } from '../../../actions/client-details';

import { ErrorLoading, Loading } from '../../../components';
import { decryptAes } from '../../../utils';

class Term extends Component {
  constructor(props) {
    super(props);

    const { identifier } = this.props.match.params;
    const { loadData } = this.props;

    loadData(identifier);
  }

  render() {
    const {
      encryptedClientDetail,
      clientDetailError,
    } = this.props;

    if(!clientDetailError && !encryptedClientDetail) {
      return <Loading />;
    }

    if(clientDetailError) {
      return <ErrorLoading />;
    }

    const decipheredData = encryptedClientDetail ? decryptAes(encryptedClientDetail) : null;
    const dataJson = JSON.parse(decipheredData);

    return (
      <ResponsiveContext.Consumer>
        {size => (
          <Box align='center'>
            <Box
              width='xlarge'
              gap={ size === 'small' ? 'large' : 'medium' }
              pad={{ horizontal: 'medium' }}
              align='center'
            >
              {/* title */}
              <Box
                width='large'
                gap='medium'
              >
                <Text
                  color='dark-1'
                  size={ size === 'small' ? 'xlarge' :  'xxlarge' }
                >
                  Política de Privacidade do Participativa
                </Text>

                <Text
                  color='dark-2'
                  size={ size === 'small' ? 'small' :  'medium' }
                >
                  Esta Política de Privacidade explica como os dados coletados através do
                  formulário são tratados, armazenados e protegidos.
                </Text>
              </Box>

              {/* Dados Coletados */}
              <Box
                width='large'
                gap='medium'
              >
                <Text
                  color='dark-1'
                  size='large'
                >
                  Dados Coletados
                </Text>

                <Text
                  color='dark-2'
                  size='small'
                >
                  Por meio do formulário, podemos coletar as seguintes informações:
                </Text>

                <Text
                  color='dark-2'
                  size='small'
                >
                  - CPF; <br />
                  - Nome completo; <br />
                  - Número de telefone; <br />
                  - E-mail; <br />
                  - Cidade; <br />
                  - Bairro; <br />
                  - Tipo, incluindo sugestões, reclamações, elogios, denúncia, contato; <br />
                  - Mensagem da manifestação; <br />
                </Text>

                <Text
                  color='dark-2'
                  size='small'
                >
                  Campos opcionais ou obrigatórios serão devidamente indicados no formulário.
                </Text>
              </Box>

              {/* Dados Coletados */}
              <Box
                width='large'
                gap='medium'
              >
                <Text
                  color='dark-1'
                  size='large'
                >
                  Finalidade do Uso dos Dados
                </Text>

                <Text
                  color='dark-2'
                  size='small'
                >
                  Os dados coletados são utilizados exclusivamente para os seguintes fins:
                </Text>

                <Text
                  color='dark-2'
                  size='small'
                >
                  - Facilitar a comunicação entre o representante político e o cidadão;
                </Text>

                <Text
                  color='dark-2'
                  size='small'
                >
                  - Atender solicitações e demandas dos cidadões;
                </Text>

                <Text
                  color='dark-2'
                  size='small'
                >
                  - Analisar temas e demandas recorrentes para melhorar o atendimento;
                </Text>

                <Text
                  color='dark-2'
                  size='small'
                >
                  - Responder às mensagens enviadas;
                </Text>

                <Text
                  color='dark-2'
                  size='small'
                >
                  - Gerar relatórios internos para fins de planejamento e gestão.
                </Text>
              </Box>


              {/* Armazenamento */}
              <Box
                width='large'
                gap='medium'
              >
                <Text
                  color='dark-1'
                  size='large'
                >
                  Armazenamento e Proteção dos Dados
                </Text>

                <Text
                  color='dark-2'
                  size='small'
                >
                  - Os dados coletados serão armazenados em servidores seguros e
                    protegidos por tecnologias adequadas contra acessos não autorizados.
                </Text>

                <Text
                  color='dark-2'
                  size='small'
                >
                  - Apenas pessoas autorizadas, como o representando político e sua equipe,
                    terão acesso aos dados coletados.
                </Text>

                <Text
                  color='dark-2'
                  size='small'
                >
                  - Os dados serão armazenados pelo período necessário para atender à
                    finalidade descrita nesta política ou conforme exigido por lei.
                </Text>
              </Box>


              {/* Compartilhamento de Dados */}
              <Box
                width='large'
                gap='medium'
              >
                <Text
                  color='dark-1'
                  size='large'
                >
                  Compartilhamento de Dados
                </Text>

                <Text
                  color='dark-2'
                  size='small'
                >
                  Os dados não serão compartilhados com terceiros, salvo:
                </Text>

                <Text
                  color='dark-2'
                  size='small'
                >
                  - Quando houver obrigatoriedade legal;
                </Text>

                <Text
                  color='dark-2'
                  size='small'
                >
                  - Com empresas contratadas para prestar serviços relacionados ao sistema de gestão do mandato (e apenas na medida necessária para a prestação do serviço).
                </Text>
              </Box>


              {/* Direitos dos cidadão */}
              <Box
                width='large'
                gap='medium'
              >
                <Text
                  color='dark-1'
                  size='large'
                >
                  Direitos dos Cidadões
                </Text>

                <Text
                  color='dark-2'
                  size='small'
                >
                  Os cidadões que utilizarem o formulário têm os seguintes direitos em relação aos seus dados pessoais:
                </Text>

                <Text
                  color='dark-2'
                  size='small'
                >
                  - Acessar seus dados: Você pode solicitar informações sobre os dados que temos armazenados.
                </Text>

                <Text
                  color='dark-2'
                  size='small'
                >
                  - Corrigir informações: Caso os dados estejam incorretos, você pode solicitar a correção.
                </Text>

                <Text
                  color='dark-2'
                  size='small'
                >
                  - Excluir dados: Você pode solicitar a exclusão de seus dados, salvo quando houver obrigação legal para armazená-los.
                </Text>

                <Text
                  color='dark-2'
                  size='small'
                >
                  - Revogar o consentimento: Você pode revogar seu consentimento para o uso dos dados a qualquer momento.
                </Text>

                <Text
                  color='dark-2'
                  size='small'
                >
                  Para exercer qualquer um desses direitos, entre em contato pelo e-mail: <br />
                  <Text size='small' weight='bold'>{dataJson?.profile?.displayEmail || process.env.REACT_APP_CONTAT_EMAIL}</Text>
                </Text>
              </Box>


              {/*  Contato para Dúvidas ou Reclamações */}
              <Box
                width='large'
                gap='medium'
              >
                <Text
                  color='dark-1'
                  size='large'
                >
                  Contato para Dúvidas ou Reclamações
                </Text>

                {dataJson?.profile?.displayName && (
                  <Text
                    color='dark-2'
                    size='small'
                  >
                    - Questões sobre o mandato ou campanha:
                      Entre em contato com a equipe do(a) <Text size='small' weight='bold'>{dataJson?.profile?.displayName}</Text> pelo e-mail <Text size='small' weight='bold'>{dataJson?.profile?.displayEmail || 'Não informado'}</Text> ou telefone <Text size='small' weight='bold'>{dataJson?.profile?.displayPhoneNumber || 'Não informado'}</Text>.
                  </Text>
                )}

                <Text
                  color='dark-2'
                  size='small'
                >
                  - Dúvidas técnicas sobre o formulário:
                    Entre em contato com o suporte técnico pelo e-mail <Text size='small' weight='bold'>{process.env.REACT_APP_CONTAT_EMAIL}</Text> ou WhatsApp <Text size='small' weight='bold'>{process.env.REACT_APP_CONTAT_PHONE}</Text>.
                </Text>
              </Box>


              {/* Atualizações desta Política */}
              <Box
                width='large'
                gap='medium'
                margin={{ bottom: 'large' }}
              >
                <Text
                  color='dark-1'
                  size='large'
                >
                  Atualizações desta Política
                </Text>

                <Text
                  color='dark-2'
                  size='small'
                >
                  Esta política pode ser alterada periodicamente para refletir melhorias no serviço ou mudanças legais.
                  Recomendamos que você revise este documento regularmente.
                </Text>

                <Text
                  color='dark-2'
                  size='small'
                >
                  A data da última atualização é <Text size='small' weight='bold'>15/01/2025</Text>.
                </Text>
              </Box>

            </Box>
          </Box>
        )}
      </ResponsiveContext.Consumer>
    )
  }
}

const mapStateToProps = ({ clientDetails }) => ({
  encryptedClientDetail: clientDetails.encryptedClientDetail,
  clientDetailError: clientDetails.clientDetailError,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({
    loadData,
  }, dispatch)

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Term));
